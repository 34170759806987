import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入进度条
import useNProgress from '@/hooks/useNProgress'
const NProgress = useNProgress()

Vue.use(VueRouter)
import userRouter from './modules/user'
const pageRouter = [ ...userRouter]
const routes = [
	{
		path: '/',
		component: () => import('@/layout/index'),
		redirect: '/home',
		children:[
			{
				path: '/home',
				component: () => import('@/views/home/index'),
			},
			{
				path: '/zfjg',
				component: () => import('@/views/zfjg/index'),
			},
			{
				path: '/naxy',
				component: () => import('@/views/naxy/index'),
			},
			{
				path: '/hgz',
				component: () => import('@/views/hgz/index'),
			},
			// {
			// 	path: '/spna',
			// 	component: () => import('@/views/spna/index'),
			// },
			{
				path: '/ncjc',
				component: () => import('@/views/ncjc/index'),
			},

			{
				path: '/jcsb',
				component: () => import('@/views/jcsb/index'),
			},
			{
				path: '/rzncp',
				component: () => import('@/views/rzncp/index'),
			},
			// {
			// 	path: '/monitor',
			// 	component: () => import('@/views/jcsb/monitor.vue'),
			// },
		]
	},
	{
		path: '/login',
		component: () => import('@/views/login/index'),
	},
	// {
	// 	path:'/',
	// 	component: () => import('@/layout/navigation'),
		
	// },
	{
		path: '/test',
		component: () => import('@/views/test/index'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('@/views/notfound/NotFound.vue'),
		meta: {
			title: '404 NotFound',
		},
	},
]

const router = new VueRouter({
	routes,
})
router.beforeEach((to, from, next) => {
	// 开启进度条
	NProgress.start()
	// let token = window.sessionStorage.getItem('token')
	// if (!token) {
	// 	return next({ path: '/home' })
	// }
	// document.title = to.meta.title

	return next()
})
router.afterEach(() => {
	// 关闭进度条
	NProgress.done()
})
export default router
