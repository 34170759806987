import axios from "axios";
import router from "@/router"
import store from '../store/index'

axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = 'http://192.168.1.30:9527'
// axios.defaults.baseURL = 'https://spapi.scssyn.com'

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        // config.headers.token = store.state.vuex_token
        config.headers.token = localStorage.getItem('token')
        config.headers.tenantId =  '1588372597175644162'
        return config;
    },
    error => {
        if (error.response.status === 401){
            router.push('/login')
        }
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.data.code === 200) {
           
            return Promise.resolve(response);
        } else if(response.data.code === 401){
            router.push('/login')
            
        }else{
            return Promise.reject(response);
        }
    },
    error => {
        if (error.data.code === 401){
            console.log('3wu');
            router.push('/login')
        }
    }
);
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: data ? data : {},
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data ? data : {},
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })

    },
    postBaseUrl(baseUrl, url, data, header) {
        return new Promise((resolve, reject) => {
            axios({
                headers: {
                    ...header
                },
                baseURL: baseUrl,
                method: 'post',
                url,
                data: data ? data : {},
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    getBaseUrl(baseUrl, url, data, header) {
        return new Promise((resolve, reject) => {
            axios({
                baseURL: baseUrl,
                headers:{
                    ...header
                },
                method: 'get',
                url,
                params: data ? data : {},
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })

    },
};
